import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const UTMHandler = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const utmSource = searchParams.get('utm_source')
    const utmMedium = searchParams.get('utm_medium')
    const utmCampaign = searchParams.get('utm_campaign')

    if (utmSource || utmMedium || utmCampaign) {
      const utmData = {
        source: utmSource,
        medium: utmMedium,
        campaign: utmCampaign,
        timestamp: new Date().toISOString()
      }

      localStorage.setItem('utmData', JSON.stringify(utmData))
    }
  }, [location])

  return <>{children}</>
}

export default UTMHandler
