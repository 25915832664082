import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useStrings } from '../../assets/localization/strings'
import { CampaignGender } from '../../models/GeneralTypes'
import { theme } from '../../theme'
import ReactHookFormSelect, { IOption } from '../common/form/ReactHookFormSelect'

// const ageOptions: IOption[] = [
//   { value: AgeOptions.age1, label: '16-18' },
//   { value: AgeOptions.age2, label: '18-30' },
//   { value: AgeOptions.age3, label: '30+' }
// ]
const CampaignAudience = () => {
  const genders = useStrings()
  const languages = useStrings()
  const strings = useStrings()

  const genderOptions: IOption<CampaignGender>[] = [
    { value: CampaignGender.ANY, label: genders.campaign_creation_gender_any },
    { value: CampaignGender.FEMALE, label: genders.campaign_creation_gender_female },
    { value: CampaignGender.MALE, label: genders.campaign_creation_gender_male },
    { value: CampaignGender.DIVERSE, label: genders.campaign_creation_gender_any }
  ]

  const languageOptions: IOption<'de' | 'en'>[] = [
    { value: 'de', label: languages.campaign_creation_language_german },
    { value: 'en', label: languages.campaign_creation_language_english }
  ]

  const renderForDefault = () => (
    <>
      <Grid item xs={12} sm={6} mt={2} pr={1} sx={{ [theme.breakpoints.only('xs')]: { padding: '0' } }}>
        <ReactHookFormSelect name='gender' label={strings.campaign_creation_gender_label} options={genderOptions} />
      </Grid>
    </>
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h5'>{strings.campaign_creation_requirements}</Typography>
      </Grid>
      {/* <Grid item xs={12} sm={6} mt={2} pr={1} sx={{ [theme.breakpoints.only('xs')]: { padding: '0' } }}>
        <ReactHookFormMultiSelect name='ageRanges' label={strings.campaign_creation_age_label} options={ageOptions} />
      </Grid> */}
      {renderForDefault()}
      <Grid item xs={12} sm={6} mt={2} pl={1} sx={{ [theme.breakpoints.only('xs')]: { padding: '0' } }}>
        <ReactHookFormSelect name='language' label={strings.campaign_creation_language_label} options={languageOptions} />
      </Grid>
    </Grid>
  )
}

export default CampaignAudience
